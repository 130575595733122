import React from 'react';
import { Calendar } from 'lucide-react';
import { motion } from 'framer-motion';
import { useLanguage } from '@/contexts/LanguageContext';
import { ImageCarousel } from './ImageCarousel';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants/routes';

export function Hero() {
  const { t } = useLanguage();
  const navigate = useNavigate();

  const handleBooking = () => {
    navigate(ROUTES.CONTACT);
  };

  return (
    <div className="relative h-screen">
      <ImageCarousel />
      <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/50">
        <div className="text-center text-white px-4 max-w-4xl mx-auto">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="font-display text-5xl md:text-7xl font-bold mb-6 leading-tight"
          >
            {t('hero.title')}
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl md:text-2xl mb-8 font-light max-w-2xl mx-auto"
          >
            {t('hero.subtitle')}
          </motion.p>
          <motion.button 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleBooking}
            className="bg-primary-600 text-white px-8 py-4 rounded-full text-lg font-medium flex items-center justify-center mx-auto hover:bg-primary-700 transition-all shadow-lg hover:shadow-xl"
          >
            <Calendar className="mr-2" />
            {t('hero.book')}
          </motion.button>
        </div>
      </div>
    </div>
  );
}