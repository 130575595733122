import React from 'react';
import { Star, Users, Calendar, Award } from 'lucide-react';
import { useLanguage } from '@/contexts/LanguageContext';
import { motion } from 'framer-motion';

export function About() {
  const { t } = useLanguage();

  return (
    <main className="pt-20">
      <section className="bg-gradient-to-b from-gray-50 to-white py-20">
        <div className="container mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-display font-bold text-center mb-12"
          >
            {t('about.title')}
          </motion.h1>
          
          <div className="max-w-3xl mx-auto text-lg text-gray-700 space-y-8">
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {t('about.description')}
            </motion.p>
            
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="grid grid-cols-2 md:grid-cols-4 gap-8 my-12"
            >
              <div className="text-center">
                <Users className="w-10 h-10 text-primary-600 mx-auto mb-3" />
                <div className="font-bold text-2xl">1000+</div>
                <div className="text-sm text-gray-600">{t('about.capacity')}</div>
              </div>
              <div className="text-center">
                <Calendar className="w-10 h-10 text-primary-600 mx-auto mb-3" />
                <div className="font-bold text-2xl">10+</div>
                <div className="text-sm text-gray-600">{t('about.experience')}</div>
              </div>
              <div className="text-center">
                <Star className="w-10 h-10 text-primary-600 mx-auto mb-3" />
                <div className="font-bold text-2xl">1000+</div>
                <div className="text-sm text-gray-600">{t('about.events')}</div>
              </div>
              <div className="text-center">
                <Award className="w-10 h-10 text-primary-600 mx-auto mb-3" />
                <div className="font-bold text-2xl">%100</div>
                <div className="text-sm text-gray-600">{t('about.satisfaction')}</div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <h2 className="text-2xl font-bold mb-6">{t('about.services')}</h2>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.weddings')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.engagements')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.circumcision')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.henna')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.corporate')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.cocktail')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.graduation')}</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-primary-600 rounded-full"></span>
                  <span>{t('services.special')}</span>
                </li>
              </ul>
            </motion.div>

            <motion.blockquote 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="border-l-4 border-primary-600 pl-6 italic my-8 text-xl"
            >
              {t('about.quote')}
            </motion.blockquote>
          </div>
        </div>
      </section>
    </main>
  );
}