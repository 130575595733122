import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '@/contexts/LanguageContext';

export function AnimatedMessage() {
  const { language } = useLanguage();

  const messages = {
    tr: {
      title: "Uğurumuz sizinle olsun",
      subtitle: "Her organizasyon unutulmaz bir anıya dönüşsün"
    },
    en: {
      title: "May our fortune be with you",
      subtitle: "Let every event turn into an unforgettable memory"
    }
  };

  const currentMessage = language === 'tr' ? messages.tr : messages.en;

  return (
    <div className="bg-gradient-to-r from-primary-50 to-primary-100/50 py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-4xl mx-auto"
        >
          <motion.h2
            animate={{ 
              scale: [1, 1.02, 1],
              opacity: [0.8, 1, 0.8] 
            }}
            transition={{ 
              duration: 3,
              repeat: Infinity,
              repeatType: "reverse"
            }}
            className="text-3xl md:text-4xl font-display font-bold text-primary-600 mb-4"
          >
            {currentMessage.title}
          </motion.h2>
          <motion.p
            animate={{ 
              y: [0, -5, 0],
              opacity: [0.7, 1, 0.7]
            }}
            transition={{ 
              duration: 4,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 1
            }}
            className="text-xl md:text-2xl text-gray-600 italic font-light"
          >
            {currentMessage.subtitle}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
}