import React from 'react';
import { Phone, Mail, MapPin, Facebook, Instagram } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '@/contexts/LanguageContext';
import { WeddingLogo } from '../common/WeddingLogo';

export function Footer() {
  const { t } = useLanguage();

  return (
    <footer className="bg-gradient-to-b from-gray-900 to-gray-950 text-white pt-16 pb-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
          <div className="space-y-6">
            <Link to="/" className="flex items-center space-x-3 group">
              <div className="transform group-hover:rotate-12 transition-transform duration-300">
                <WeddingLogo className="h-12 w-12" />
              </div>
              <span className="text-2xl font-bold">Salon Uğur</span>
            </Link>
            <p className="text-gray-400 leading-relaxed">
              {t('footer.about')}
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-6">{t('footer.quickLinks')}</h3>
            <ul className="space-y-4">
              <li>
                <Link to="/" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.home')}
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.about')}
                </Link>
              </li>
              <li>
                <Link to="/gallery" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.gallery')}
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-white transition-colors">
                  {t('nav.contact')}
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-6">{t('footer.contact')}</h3>
            <ul className="space-y-4">
              <li className="flex items-center space-x-3">
                <Phone className="w-5 h-5 text-primary-500" />
                <span className="text-gray-400">0212 428 11 06</span>
              </li>
              <li className="flex items-center space-x-3">
                <Mail className="w-5 h-5 text-primary-500" />
                <span className="text-gray-400">info@salonugur.com</span>
              </li>
              <li className="flex items-start space-x-3">
                <MapPin className="w-5 h-5 text-primary-500 mt-1" />
                <span className="text-gray-400">
                  Namık Kemal Mah., Esenyurt<br />
                  İstanbul, Turkey
                </span>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-6">{t('footer.followUs')}</h3>
            <div className="flex space-x-4">
              <a 
                href="https://www.facebook.com/people/Salon-U%C4%9Fur-Esenyurt-Ugur-D%C3%BC%C4%9F%C3%BCn-Salonu/100057545016184/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-primary-500 transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a 
                href="#"
                className="text-gray-400 hover:text-primary-500 transition-colors"
              >
                <Instagram className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Salon Uğur. {t('footer.rights')}</p>
        </div>
      </div>
    </footer>
  );
}