import React from 'react';
import { motion } from 'framer-motion';

export function WeddingLogo({ className = "h-8 w-8" }: { className?: string }) {
  return (
    <motion.svg
      viewBox="0 0 100 100"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      whileHover={{ scale: 1.1 }}
      transition={{ duration: 0.3 }}
    >
      {/* Arka Plan Daire */}
      <motion.circle
        cx="50"
        cy="50"
        r="45"
        fill="url(#gradientBg)"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
      />
      
      {/* Uğur Böceği Gövdesi */}
      <motion.path
        d="M50 15C30 15 20 35 20 50C20 65 35 85 50 85C65 85 80 65 80 50C80 35 70 15 50 15Z"
        fill="url(#gradientBody)"
        stroke="white"
        strokeWidth="2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      />
      
      {/* Gelin-Damat Silueti */}
      <motion.path
        d="M35 65C35 50 45 45 50 45C55 45 65 50 65 65"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      />
      
      {/* Kalp */}
      <motion.path
        d="M50 25C45 20 35 20 35 30C35 40 50 45 50 45C50 45 65 40 65 30C65 20 55 20 50 25Z"
        fill="white"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.9, type: "spring" }}
      />
      
      {/* Uğur Noktaları */}
      <motion.g
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.1, staggerChildren: 0.1 }}
      >
        {[0, 1, 2, 3, 4, 5].map((i) => (
          <motion.circle
            key={i}
            cx={40 + (i % 3) * 10}
            cy={55 + Math.floor(i / 3) * 10}
            r="3"
            fill="white"
            whileHover={{ scale: 1.2 }}
          />
        ))}
      </motion.g>
      
      {/* Kanatlar */}
      <motion.g
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.3 }}
      >
        <path
          d="M30 40C20 30 25 20 35 25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M70 40C80 30 75 20 65 25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </motion.g>

      {/* Gradients */}
      <defs>
        <linearGradient id="gradientBg" x1="0" y1="0" x2="100" y2="100">
          <stop offset="0%" stopColor="#E11D48" />
          <stop offset="100%" stopColor="#BE123C" />
        </linearGradient>
        <linearGradient id="gradientBody" x1="50" y1="15" x2="50" y2="85">
          <stop offset="0%" stopColor="#E11D48" />
          <stop offset="100%" stopColor="#BE123C" />
        </linearGradient>
      </defs>
    </motion.svg>
  );
}