import React from 'react';
import { useLanguage } from '@/contexts/LanguageContext';
import { motion } from 'framer-motion';

interface LanguageSwitchProps {
  isScrolled?: boolean;
}

export function LanguageSwitch({ isScrolled = false }: LanguageSwitchProps) {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="flex items-center space-x-2">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setLanguage('tr')}
        className={`px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 ${
          language === 'tr' 
            ? 'bg-primary-600 text-white shadow-lg' 
            : isScrolled
              ? 'text-gray-700 hover:bg-gray-100'
              : 'text-white hover:bg-white/20'
        }`}
      >
        TR
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setLanguage('en')}
        className={`px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-200 ${
          language === 'en' 
            ? 'bg-primary-600 text-white shadow-lg' 
            : isScrolled
              ? 'text-gray-700 hover:bg-gray-100'
              : 'text-white hover:bg-white/20'
        }`}
      >
        EN
      </motion.button>
    </div>
  );
}