import React from 'react';
import { Phone, Mail, MapPin, Facebook, Instagram, Clock, Calendar } from 'lucide-react';
import { motion } from 'framer-motion';
import { useLanguage } from '@/contexts/LanguageContext';

export function Contact() {
  const { t } = useLanguage();

  return (
    <main className="pt-20">
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-5xl font-display font-bold text-gray-900 mb-4">
              İletişim
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Hayalinizdeki organizasyon için bizimle iletişime geçin
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="space-y-8"
            >
              <div>
                <h2 className="text-2xl font-semibold mb-6">İletişim Bilgileri</h2>
                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <MapPin className="w-6 h-6 text-primary-600 mt-1 flex-shrink-0" />
                    <div>
                      <h3 className="font-medium mb-1">Adres</h3>
                      <p className="text-gray-600">
                        Namık Kemal Mahallesi, 12. Sokak No:2<br />
                        34513 Esenyurt – İstanbul
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <Phone className="w-6 h-6 text-primary-600 flex-shrink-0" />
                    <div>
                      <h3 className="font-medium mb-1">Telefon</h3>
                      <p className="text-gray-600">Tel: 0212 428 11 06</p>
                      <p className="text-gray-600">Fax: 0212 428 11 07</p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <Mail className="w-6 h-6 text-primary-600 flex-shrink-0" />
                    <div>
                      <h3 className="font-medium mb-1">E-posta</h3>
                      <p className="text-gray-600">info@salonugur.com</p>
                    </div>
                  </div>

                  <div>
                    <h3 className="font-medium mb-2">Yönetim İletişim</h3>
                    <div className="space-y-2 text-gray-600">
                      <p>Zeki Akyüz: 0530 364 41 24</p>
                      <p>Cafer Uçak: 0532 323 94 17</p>
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <a 
                      href="https://www.facebook.com/people/Salon-U%C4%9Fur-Esenyurt-Ugur-D%C3%BC%C4%9F%C3%BCn-Salonu/100057545016184/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary-600 hover:text-primary-700 transition-colors"
                    >
                      <Facebook className="w-6 h-6" />
                    </a>
                    <a 
                      href="#"
                      className="text-primary-600 hover:text-primary-700 transition-colors"
                    >
                      <Instagram className="w-6 h-6" />
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="space-y-8"
            >
              <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.2718440892944!2d28.692307700000003!3d41.013134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa094efd9e5ef%3A0x47103083d19b24af!2sSalon%20U%C4%9Fur!5e0!3m2!1str!2str!4v1709668799027!5m2!1str!2str"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="rounded-lg"
                  title="Salon Uğur Konum"
                ></iframe>
              </div>

              <div className="bg-primary-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4 text-primary-900">Ulaşım</h3>
                <p className="text-primary-700">
                  Metrobüs Esenyurt durağından inip, 145T veya 146B otobüsleri ile kolayca ulaşabilirsiniz.
                  Ayrıca özel aracınız için misafir otoparkımız mevcuttur.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </main>
  );
}