import React from 'react';
import { Hero } from '../components/home/Hero';
import { Features } from '../components/home/Features';
import { AnimatedMessage } from '../components/home/AnimatedMessage';

export function Home() {
  return (
    <main>
      <Hero />
      <AnimatedMessage />
      <Features />
    </main>
  );
}