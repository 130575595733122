import React, { useState, useEffect } from 'react';
import { X, PartyPopper, Calendar } from 'lucide-react';
import { useLanguage } from '@/contexts/LanguageContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/constants/routes';

export function DiscountPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasSeenPopup = localStorage.getItem('hasSeenDiscountPopup');
      if (!hasSeenPopup) {
        setIsOpen(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('hasSeenDiscountPopup', 'true');
  };

  const handleBooking = () => {
    handleClose();
    navigate(ROUTES.CONTACT);
  };

  const content = {
    tr: {
      title: "Yaz Sezonu Fırsatı!",
      description: "2024 yaz sezonu düğün ve organizasyon rezervasyonlarında özel indirim fırsatını kaçırmayın!",
      discount: "%25'e Varan İndirim",
      code: "YAZ2024",
      button: "Rezervasyon Yap",
      validUntil: "30 Nisan 2024'e kadar geçerlidir"
    },
    en: {
      title: "Summer Season Offer!",
      description: "Don't miss the special discount opportunity for wedding and event reservations in summer 2024!",
      discount: "Up to 25% Discount",
      code: "SUMMER2024",
      button: "Book Now",
      validUntil: "Valid until April 30, 2024"
    }
  };

  const currentContent = language === 'tr' ? content.tr : content.en;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          onClick={handleClose}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-2xl shadow-2xl max-w-md w-full relative overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-gradient-to-r from-primary-600 to-primary-700 p-6 text-white relative overflow-hidden">
              <div className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4">
                <PartyPopper className="w-32 h-32 opacity-20" />
              </div>
              <h2 className="text-2xl font-bold relative z-10">{currentContent.title}</h2>
            </div>

            <button
              onClick={handleClose}
              className="absolute right-4 top-4 text-white hover:text-gray-200 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
            
            <div className="p-6">
              <p className="text-gray-600 mb-6">
                {currentContent.description}
              </p>

              <div className="bg-primary-50 p-6 rounded-xl text-center mb-6">
                <motion.div
                  initial={{ scale: 0.5 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", stiffness: 300, damping: 15 }}
                  className="text-4xl font-bold text-primary-600 mb-2"
                >
                  {currentContent.discount}
                </motion.div>
                <div className="bg-white px-4 py-2 rounded-lg inline-block">
                  <code className="text-primary-600 font-mono font-bold">
                    {currentContent.code}
                  </code>
                </div>
                <p className="text-sm text-primary-600 mt-2">
                  {currentContent.validUntil}
                </p>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleBooking}
                className="w-full bg-primary-600 text-white py-3 rounded-xl font-medium hover:bg-primary-700 transition-colors flex items-center justify-center gap-2"
              >
                <Calendar className="w-5 h-5" />
                {currentContent.button}
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}