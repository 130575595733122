import React, { useState } from 'react';
import { useLanguage } from '@/contexts/LanguageContext';
import { motion } from 'framer-motion';

const categories = [
  { id: 'all', tr: 'Tümü', en: 'All' },
  { id: 'salon', tr: 'Salon', en: 'Venue' },
  { id: 'dekorasyon', tr: 'Dekorasyon', en: 'Decoration' },
  { id: 'organizasyon', tr: 'Organizasyon', en: 'Events' }
];

const images = [
  {
    url: '/images/salon-ugur-1.jpg',
    category: 'salon',
    title: { tr: 'Ana Salon', en: 'Main Hall' }
  },
  {
    url: '/images/salon-ugur-2.jpg',
    category: 'dekorasyon',
    title: { tr: 'Masa Düzeni', en: 'Table Setting' }
  },
  {
    url: '/images/salon-ugur-3.jpg',
    category: 'salon',
    title: { tr: 'Salon Görünümü', en: 'Venue View' }
  },
  {
    url: '/images/salon-ugur-4.jpg',
    category: 'dekorasyon',
    title: { tr: 'Özel Dekorasyon', en: 'Special Decoration' }
  },
  {
    url: '/images/salon-ugur-5.jpg',
    category: 'salon',
    title: { tr: 'Bina Dış Görünüm', en: 'Building Exterior' }
  },
  {
    url: '/images/salon-ugur-6.jpg',
    category: 'organizasyon',
    title: { tr: 'Düğün Organizasyonu', en: 'Wedding Event' }
  }
];

export function Gallery() {
  const { language } = useLanguage();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedImage, setSelectedImage] = useState(null);

  const filteredImages = selectedCategory === 'all'
    ? images
    : images.filter(img => img.category === selectedCategory);

  return (
    <main className="pt-20">
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-display font-bold text-center mb-12"
          >
            {language === 'tr' ? 'Galeri' : 'Gallery'}
          </motion.h1>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="flex flex-wrap justify-center gap-4 mb-12"
          >
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-6 py-2 rounded-full transition-all ${
                  selectedCategory === category.id
                    ? 'bg-primary-600 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {language === 'tr' ? category.tr : category.en}
              </button>
            ))}
          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            {filteredImages.map((image, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * index }}
                className="relative group cursor-pointer overflow-hidden rounded-lg shadow-lg"
                onClick={() => setSelectedImage(image)}
              >
                <img
                  src={image.url}
                  alt={language === 'tr' ? image.title.tr : image.title.en}
                  className="w-full h-64 object-cover transform transition-transform duration-500 group-hover:scale-110"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity flex items-center justify-center">
                  <p className="text-white text-lg font-semibold opacity-0 group-hover:opacity-100 transition-opacity transform translate-y-4 group-hover:translate-y-0">
                    {language === 'tr' ? image.title.tr : image.title.en}
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
          onClick={() => setSelectedImage(null)}
        >
          <div className="max-w-4xl w-full">
            <img
              src={selectedImage.url}
              alt={language === 'tr' ? selectedImage.title.tr : selectedImage.title.en}
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      )}
    </main>
  );
}