import React from 'react';
import { 
  Users, Music, Wifi, Camera, Coffee, Car, Wind, Shield,
  Utensils, Monitor, BookOpen, Baby, Speaker, Calendar
} from 'lucide-react';
import { motion } from 'framer-motion';

const features = [
  {
    icon: Users,
    title: '1200m² Alan',
    description: '1000 kişilik misafir kapasitesi'
  },
  {
    icon: Car,
    title: 'Otopark',
    description: 'Misafirleriniz için özel otopark alanı'
  },
  {
    icon: Wind,
    title: 'İklimlendirme',
    description: 'Merkezi ısıtma, soğutma ve havalandırma'
  },
  {
    icon: Music,
    title: 'Profesyonel Ses',
    description: 'Modern ses ve ışık sistemleri'
  },
  {
    icon: Shield,
    title: 'Güvenlik',
    description: '8 farklı noktada kamera sistemi'
  },
  {
    icon: Utensils,
    title: 'Mutfak',
    description: 'Tam donanımlı profesyonel mutfak'
  },
  {
    icon: Monitor,
    title: 'Sinevizyon',
    description: 'Özel gösterimler için ekran sistemi'
  },
  {
    icon: Speaker,
    title: 'Orkestra',
    description: '6 kişilik profesyonel orkestra'
  },
  {
    icon: Camera,
    title: 'Fotoğraf & Video',
    description: 'Yüksek kaliteli çekim hizmeti'
  },
  {
    icon: Coffee,
    title: 'Büfe',
    description: 'Zengin ikram seçenekleri'
  },
  {
    icon: BookOpen,
    title: 'Anı Defteri',
    description: 'Misafirlerinizin mesajları için'
  },
  {
    icon: Baby,
    title: 'Bebek Odası',
    description: 'Bebek bakım odası'
  }
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.1 }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function Features() {
  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="font-display text-4xl md:text-5xl font-bold mb-6 text-gray-900">
            Salon Özelliklerimiz
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            1200m²'lik alanımızda sizlere en iyi hizmeti sunmak için her detayı düşündük
          </p>
        </motion.div>
        
        <motion.div 
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={item}
              className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow group"
            >
              <div className="bg-primary-50 w-14 h-14 rounded-xl flex items-center justify-center mb-4 group-hover:bg-primary-100 transition-colors">
                <feature.icon className="w-7 h-7 text-primary-600" />
              </div>
              <h3 className="text-lg font-semibold mb-2 text-gray-900">{feature.title}</h3>
              <p className="text-gray-600 text-sm">{feature.description}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <a 
            href="/contact" 
            className="inline-flex items-center gap-2 bg-primary-600 text-white px-8 py-3 rounded-full hover:bg-primary-700 transition-colors"
          >
            <Calendar className="w-5 h-5" />
            Rezervasyon Yap
          </a>
        </motion.div>
      </div>
    </section>
  );
}